import { useDic } from "@/components/Dic/useDic.hook";
import { t } from "@/translations";
import { useMutationLoadable } from "./useMutationLoadable.hook";
import { extractUUID, responseErrorMessage } from "./utils";
import { addUUID } from "@/utils/state/error.utils";
import { UserAbortError } from "@/components/Mappers/ConflictModal";

export const notifySuccess = ({ messageService, success, result }) => {
    const content =
        typeof success === "function"
            ? success(result)
            : success
            ? t(success)
            : null;
    if (content)
        messageService.success({
            content,
        });
};

export const notifyError = ({ messageService, error, e }) => {
    if (e instanceof UserAbortError) return;

    const content =
        typeof error === "function"
            ? error === responseErrorMessage // used across app, already adds UUID
                ? error(e)
                : addUUID(error(e), extractUUID(e))
            : error
            ? addUUID(t(error), extractUUID(e))
            : null;
    if (content) messageService.error({ content });

    throw e;
};

export const notifyAboutStatus =
    (
        messageService,
        asyncFn,
        success = "general.saved",
        error = responseErrorMessage,
    ) =>
    async (...args) => {
        try {
            const result = await asyncFn(...args);
            notifySuccess({ messageService, success, result });
            return result;
        } catch (e) {
            notifyError({ messageService, error, e });
        }
    };

export const useMutationLoadableWithNotification = (
    fn,
    deps,
    success,
    error,
) => {
    const { messageService } = useDic();
    const wrappedFn = notifyAboutStatus(messageService, fn, success, error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMutationLoadable(wrappedFn, deps);
};
