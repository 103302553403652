import { ButtonMenu } from "@/components/DesignSystem";
import { SecurityContext } from "@/security/authorization";
import { includesPermission } from "@/security/permission.utils";
import { t } from "@/translations";
import omit from "lodash/fp/omit";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { connect } from "react-redux";
import { MAX_EXPORT_SIZE } from "../PagableTable/PageableTable";

const initialState = {
    title: null,
    breadcrumbs: [],
    actionButtons: [],
    headerTabs: undefined,
    noAppLayout: false,
};

export const PageLayoutContext = React.createContext(initialState);

const updateQueue =
    ({ ...newQueueItem }) =>
    queue => {
        const index = queue.findIndex(q => q.id === newQueueItem.id);

        if (!newQueueItem.items?.length)
            return queue.filter(queueItem => queueItem.id !== newQueueItem.id);

        if (index > -1) {
            const newQueue = [...queue];
            newQueue.splice(index, 1, newQueueItem);
            return newQueue;
        }

        return [newQueueItem, ...queue];
    };

const useSettings = () => {
    const [menuItemsQueue, setMenuItemsQueue] = useState([]);
    const setMenuItems = useCallback(
        ({ id, items }) => setMenuItemsQueue(updateQueue({ id, items })),
        [],
    );
    // Fail fast, more than one item should be just intermediate state
    const [queueItem] = menuItemsQueue.length === 1 ? menuItemsQueue : [];

    return {
        settingsVisible: queueItem?.items?.length > 0,
        settingsItems: queueItem?.items ?? [],
        setMenuItems,
    };
};

const PageLayoutContextProvider = connect(
    state => ({
        breadcrumbButtons: state.breadcrumbButtons,
    }),
    null,
)(({ breadcrumbButtons, children }) => {
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [backButtonAction, setBackButtonAction] = useState(null);
    const [hideBackButton, setHideBackButton] = useState(false);
    const [hideActionButtons, setHideActionButtons] = useState(false);
    const [refreshAction, setRefreshAction] = useState(null);
    const [exportButtonSettings, setExportButtonSettings] = useState({});
    const { settingsVisible, settingsItems, setMenuItems } = useSettings();
    const securityContext = useContext(SecurityContext);
    const actionButtons = useMemo(
        () => [
            ...breadcrumbButtons
                .filter(breadcrumbButton => {
                    if (breadcrumbButton?.config?.permissionsFunc) {
                        return breadcrumbButton.config.permissionsFunc(
                            securityContext,
                            breadcrumbButton.permissions,
                        );
                    }
                    return includesPermission(
                        securityContext,
                        breadcrumbButton.permissions,
                    );
                })
                .map(
                    ({
                        onClick,
                        label,
                        loading,
                        disabled,
                        type = "primary",
                        config,
                        ...rest
                    }) => ({
                        // TODO: type, config
                        key: label,
                        size: "small",
                        type: config?.type ?? type,
                        icon: config?.icon,
                        onClick: onClick,
                        loading: loading ?? false,
                        disabled: disabled ?? false,
                        tooltip: config?.tooltip,
                        label: t(label),
                        track: config?.track,
                    }),
                ),
            exportButtonSettings?.exportVisible && {
                key: "export",
                size: "small",
                // type,
                disabled: exportButtonSettings.exportDisabled,
                tooltip:
                    exportButtonSettings.exportDisabled &&
                    t("export.tooltip.max-record", {
                        maxRows: MAX_EXPORT_SIZE,
                    }),
                label: exportButtonSettings.text,
                onClick: exportButtonSettings.onClick,
                track: exportButtonSettings.track,
                ...omit(
                    ["exportVisible", "exportDisabled", "onClick"],
                    exportButtonSettings,
                ),
            },
            refreshAction !== null && {
                ...ButtonMenu.buttons.refresh(),
                "data-test": "page-layout-refresh",
                onClick: () => refreshAction(),
            },
            settingsVisible && {
                ...ButtonMenu.buttons.settings(),
                "data-test": "TablePreferencesMenuDropdown-open",
                items: settingsItems,
            },
        ],
        [
            breadcrumbButtons,
            exportButtonSettings,
            refreshAction,
            settingsVisible,
            settingsItems,
            securityContext,
        ],
    );

    const [headerTabs, setHeaderTabs] = useState(undefined);
    const menuItems = settingsItems;

    return (
        <PageLayoutContext.Provider
            value={{
                title,
                setPageTitle: setTitle,
                description,
                setPageDescription: setDescription,
                setExportButtonSettings,
                backButtonAction,
                setBackButtonAction,
                hideBackButton,
                setHideBackButton: setHideBackButton,
                hideActionButtons,
                setHideActionButtons,
                setRefreshAction,
                menuItems,
                setMenuItems: setMenuItems,
                actionButtons,
                headerTabs,
                setHeaderTabs,
            }}
        >
            {children}
        </PageLayoutContext.Provider>
    );
});

PageLayoutContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    breadcrumbButtons: PropTypes.array,
};

export default PageLayoutContextProvider;
