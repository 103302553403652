import PropTypes from "prop-types";
import React, { useCallback, useContext, useMemo, useState } from "react";

const CustomModalContext = React.createContext({
    show: () => console.log("Provide implementation for CustomModalContext"),
    hide: () => console.log("Provide implementation for CustomModalContext"),
});

// TODO: rename to imperative modal
export const useCustomModal = () => useContext(CustomModalContext);

export const CustomModalProvider = ({ children }) => {
    const [modal, setModal] = useState(null);
    const show = useCallback(el => setModal(el), [setModal]);
    const hide = useCallback(() => setModal(null), [setModal]);
    const modalProviderProps = {
        onClose: hide,
        visible: true,
    };
    const modalWithProps = modal
        ? React.cloneElement(modal, { modalProviderProps })
        : null;
    const value = useMemo(() => ({ show, hide }), [show, hide]);

    return (
        <CustomModalContext.Provider value={value}>
            {children}
            {modalWithProps}
        </CustomModalContext.Provider>
    );
};

CustomModalProvider.propTypes = {
    children: PropTypes.node,
};

export const ModalProviderPropsPropType = () =>
    PropTypes.shape({
        onClose: PropTypes.func,
        visible: PropTypes.bool,
    });
