import React from "react";
import { useWorkflowApprovalExistsQuery } from "./loadables";
import { EVENT_ORCHESTRATION_APPROVAL } from "./Workflow.utils";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";

export const ApprovalWorkflowsEnabledContext = React.createContext({
    [EVENT_ORCHESTRATION_APPROVAL]: undefined,
});

export const ApprovalWorkflowsEnabledProvider = ({ children }) => {
    const { accountId } = useAccountAppParams();
    const context = {
        EVENT_ORCHESTRATION_APPROVAL: useWorkflowApprovalExistsQuery({
            projectId: accountId,
            wfType: EVENT_ORCHESTRATION_APPROVAL,
        }).loadable.valueMaybe(),
    };

    return (
        <ApprovalWorkflowsEnabledContext.Provider value={context}>
            {children}
        </ApprovalWorkflowsEnabledContext.Provider>
    );
};
