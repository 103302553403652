import { useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { useDic } from "../Dic/useDic.hook";

export const useWorkflowApprovalExistsQuery = ({ projectId, wfType }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !projectId
                ? Promise.resolve(undefined)
                : axiosService
                      .get(
                          `/api/projects/${projectId}/approval-workflows/${wfType}/exists`,
                      )
                      .then(getData),
        [axiosService, projectId, wfType],
    );
};
