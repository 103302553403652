import { Switch } from "@/components/DesignSystem";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { needsWorkflowApproval } from "@/components/WorkFlow/workflowNotification";
import {
    hasError,
    hasValue,
    isLoading,
    useMutationLoadableWithNotification,
} from "@/modules/loadable";
import { always, identity } from "lodash/fp";
import React, { useEffect, useRef, useState } from "react";

const createUseMutation =
    ({ performCall, success, error }) =>
    () =>
        useMutationLoadableWithNotification(performCall, [], success, error);

const defaultGetSuccessValue = ({
    enabledResponseProp,
    data,
    currentValue,
    isOptimistic,
}) => {
    if (enabledResponseProp && typeof data?.[enabledResponseProp] === "boolean")
        return data?.[enabledResponseProp];
    if (isOptimistic) return currentValue;
    return !currentValue;
};

const workflowApprovalGetSuccessValue = ({
    data,
    currentValue,
    enabledResponseProp,
}) => {
    if (needsWorkflowApproval(data)) return currentValue;
    if (typeof data?.data?.[enabledResponseProp] === "boolean")
        // API broken?
        return data?.data?.[enabledResponseProp];
    return currentValue;
};

const workflowApprovalGetDisabled = ({ loadable, disabled }) => {
    const dataMaybe = loadable.valueMaybe();
    if (!dataMaybe) return disabled;
    if (needsWorkflowApproval(dataMaybe)) return true;
    return disabled;
};

export const SwitchAsync = ({
    initialValue,
    onChange,
    performCall,
    success,
    error,
    hookParams = {},
    useMutation = createUseMutation({ performCall, success, error }),
    from = identity,
    enabledResponseProp,
    isWorkflowApprovalMaybe,
    getSuccessValue = isWorkflowApprovalMaybe
        ? workflowApprovalGetSuccessValue
        : defaultGetSuccessValue,
    disabled,
    size = "small",
    isOptimistic = !isWorkflowApprovalMaybe,
    getDisabled = isWorkflowApprovalMaybe
        ? workflowApprovalGetDisabled
        : always(disabled),
    ...props
}) => {
    const [checked, setChecked] = useState(initialValue);
    const { mutate, loadable } = useMutation(hookParams) ?? {};
    const handleChange = checked => {
        if (isOptimistic) setChecked(checked);
        mutate(from({ checked }));
        onChange?.(checked);
    };

    const loadableRef = useRef(loadable);
    loadableRef.current = loadable;
    const getSuccessValueCurrent = useCurrentHandler(getSuccessValue);
    useEffect(() => {
        const onResponse = loadable => {
            setChecked(currentValue => {
                // debugger;
                if (hasValue(loadable))
                    return getSuccessValueCurrent({
                        currentValue,
                        data: loadable.valueMaybe(),
                        isOptimistic,
                        enabledResponseProp,
                    });
                if (hasError(loadable)) {
                    if (isOptimistic) return !currentValue;
                    else return currentValue;
                }
                throw new Error("never happens");
            });
        };
        if (isLoading(loadable))
            return () => {
                if (!isLoading(loadableRef.current)) {
                    // debugger;
                    onResponse(loadableRef.current);
                }
            };
    }, [enabledResponseProp, getSuccessValueCurrent, isOptimistic, loadable]);

    return (
        <Switch
            value={checked}
            onChange={handleChange}
            loading={isLoading(loadable)}
            disabled={getDisabled({ loadable, disabled })}
            size={size}
            {...props}
        />
    );
};
