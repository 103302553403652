import { t } from "@/translations";

export const PM_DOCUMENTATION =
    "https://pricefx.atlassian.net/wiki/spaces/PM/overview";
export const PM_FIRST_LOGIN_HELP =
    "https://pricefx.atlassian.net/wiki/display/PM/Login";
export const CONFLUENCE_SEARCH_URL =
    "https://pricefx.atlassian.net/wiki/search";
export const PRIVACY_POLICY_URL =
    "https://www.pricefx.com/impressum-privacy-policy/";
export const PM_RELEASE_DOCUMENTATION_URL =
    "https://pricefx.atlassian.net/wiki/display/PM/Release+Notes";
export const ACCOUNT_API_TOKEN_DOCUMENTATION_URL =
    "https://pricefx.atlassian.net/wiki/display/PM/Account+API+Token";
export const ENV = {
    production: "production",
    qa: "qa",
    development: "development",
};

export const EntityType = Object.freeze({
    PROJECT: "PROJECT",
    PARTITION: "PARTITION",
    INTEGRATION: "INTEGRATION",
    PACKAGE: "PACKAGE",
});

export const InstanceServerType = Object.freeze({
    MANUAL: "MANUAL",
    PROVISIONED: "PROVISIONED",
});

export const HealthState = Object.freeze({
    UP: "UP",
    DOWN: "DOWN",
});

export const TemplateType = Object.freeze({
    PACKAGES: "PACKAGES",
    INTEGRATIONS: "INTEGRATIONS",
    SOLUTIONS: "SOLUTIONS",
    NON_APPROVED: "NON_APPROVED",
});

export const ReportType = Object.freeze({
    STARTED: "STARTED",
    FINISHED: "FINISHED",
    FAILED: "FAILED",
});

export const ConnectionStatus = Object.freeze({
    DRAFT: "DRAFT",
    DEPLOYED: "DEPLOYED",
    UNDEPLOYED: "UNDEPLOYED",
    CHANGED: "CHANGED",
});

export const WorkflowType = Object.freeze({
    CREATE_PARTITION_APPROVAL: "CREATE_PARTITION_APPROVAL",
    DELETE_CLUSTER_APPROVAL: "DELETE_CLUSTER_APPROVAL",
    PERMISSION_ASSIGNMENT_APPROVAL: "PERMISSION_ASSIGNMENT_APPROVAL",
    DELETE_INTEGRATION_MANAGER_INSTANCE_APPROVAL:
        "DELETE_INTEGRATION_MANAGER_INSTANCE_APPROVAL",
    DELETE_PARTITION_APPROVAL: "DELETE_PARTITION_APPROVAL",
    CALCULATION_ENGINE_APPROVAL: "CALCULATION_ENGINE_APPROVAL",
    PARTITION_PROVISIONING_APPROVAL: "PARTITION_PROVISIONING_APPROVAL",
    PFX_ENG_SUPPORT_USER_APPROVAL: "PFX_ENG_SUPPORT_USER_APPROVAL",
    SAML_SSO_APPROVAL: "SAML_SSO_APPROVAL",
    EVENT_ORCHESTRATION_APPROVAL: "EVENT_ORCHESTRATION_APPROVAL",
    COPILOT_APPROVAL: "COPILOT_APPROVAL",

    translate: function (type) {
        switch (type) {
            case WorkflowType.CREATE_PARTITION_APPROVAL:
                return t("workflows.type.create-partition-approval");
            case WorkflowType.PERMISSION_ASSIGNMENT_APPROVAL:
                return t("workflows.type.permission-assignment-approval");
            case WorkflowType.DELETE_CLUSTER_APPROVAL:
                return t("workflows.type.delete-cluster-approval");
            case WorkflowType.DELETE_INTEGRATION_MANAGER_INSTANCE_APPROVAL:
                return t("workflows.type.delete-im-instance-approval");
            case WorkflowType.DELETE_PARTITION_APPROVAL:
                return t("workflows.type.delete-partition-approval");
            case WorkflowType.CALCULATION_ENGINE_APPROVAL:
                return t("workflows.type.calculation-engine-approval");
            case WorkflowType.PARTITION_PROVISIONING_APPROVAL:
                return t("workflows.type.partition-provisioning-approval");
            case WorkflowType.PFX_ENG_SUPPORT_USER_APPROVAL:
                return t("workflows.type.pfx-eng-support-user-approval");
            case WorkflowType.SAML_SSO_APPROVAL:
                return t("workflows.type.saml-sso-approval");
            case WorkflowType.EVENT_ORCHESTRATION_APPROVAL:
                return t("workflows.type.event-orchestration-approval");
            case WorkflowType.COPILOT_APPROVAL:
                return t("workflows.type.copilot-approval");
        }
    },
});

export const IntegrationType = Object.freeze({
    CUSTOM: "CUSTOM",
    TEMPLATE: "TEMPLATE",

    translate: function (type) {
        switch (type) {
            case IntegrationType.CUSTOM:
                return t("constant.integration-type.custom");
            case IntegrationType.TEMPLATE:
                return t("constant.integration-type.template");
        }
    },
});
