import { EntityType } from "@/constants/CommonConstats";
import concat from "lodash/concat";
import filter from "lodash/filter";
import values from "lodash/fp/values";
import get from "lodash/get";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";

export const ACCOUNT_LIST_READ_PERMISSION = "account.list.read";
export const PARTITION_LIST_READ_PERMISSION = "partition.list.read";
export const INTEGRATION_LIST_READ_PERMISSION = "integration.list.read";
export const NODES_LIST_READ_PERMISSION = "nodes.list.read";
export const UPDATES_READ_PERMISSION = "updates.read";
export const SERVERS_PERMISSION = ["servers.read", "servers.edit"];
export const CLUSTER_UPGRADES_LIST_READ_PERMISSIONS =
    "cluster_upgrades.list.read";
export const CLUSTER_UPGRADES_SLOTS_OVERVIEW_PERMISSIONS =
    "cluster_upgrades.slots.overview";
export const CLUSTERS_LIST_READ_PERMISSIONS = "clusters.list.read";
export const CLUSTER_ADMIN = "cluster.admin";
export const REMOTE_LOGIN_PERMISSIONS = [
    "loginAs_pricefx-support",
    "loginAs_root",
];

export const OVERVIEW_PERMISSIONS = [
    ACCOUNT_LIST_READ_PERMISSION,
    PARTITION_LIST_READ_PERMISSION,
    INTEGRATION_LIST_READ_PERMISSION,
    NODES_LIST_READ_PERMISSION,
    UPDATES_READ_PERMISSION,
    ...SERVERS_PERMISSION,
    CLUSTER_UPGRADES_LIST_READ_PERMISSIONS,
    CLUSTER_UPGRADES_SLOTS_OVERVIEW_PERMISSIONS,
    CLUSTERS_LIST_READ_PERMISSIONS,
    ...REMOTE_LOGIN_PERMISSIONS,
];

export const PERMISSION_POWER_USER_PERMISSION = "permission.power.user";
export const VERSIONS_MANAGEMENT_PERMISSION = "versions.management";

export const PRICEFX_TECH_OPS_PERMISSION = "pricefx.tech_ops";
export const PRICEFX_INTERNAL_USER_PERMISSION = "pricefx.internal_user";

export const GLOBAL_ALERT_READ_PERMISSION = "alerts.global.read";
export const GLOBAL_ALERT_EDIT_PERMISSION = "alerts.global.edit";
export const GLOBAL_ALERTS_PERMISSIONS = [
    GLOBAL_ALERT_READ_PERMISSION,
    GLOBAL_ALERT_EDIT_PERMISSION,
];
export const USER_MANAGEMENT_READ_PERMISSION = "user_mgmt.users.read";
export const USER_MANAGEMENT_EDIT_PERMISSION = "user_mgmt.users.edit";
export const USERS_MANAGEMENT_PERMISSIONS = [
    USER_MANAGEMENT_READ_PERMISSION,
    USER_MANAGEMENT_EDIT_PERMISSION,
];
export const GROUP_MANAGEMENT_READ_PERMISSION = "user_mgmt.groups.read";
export const GROUP_MANAGEMENT_EDIT_PERMISSION = "user_mgmt.groups.edit";
export const GROUPS_MANAGEMENT_PERMISSIONS = [
    GROUP_MANAGEMENT_READ_PERMISSION,
    GROUP_MANAGEMENT_EDIT_PERMISSION,
];

export const FEATURE_FLAGS_READ = "feature_flags.read";
export const FEATURE_FLAGS_EDIT = "feature_flags.edit";

export const FEATURE_FLAGS_PERMISSIONS = [
    FEATURE_FLAGS_READ,
    FEATURE_FLAGS_EDIT,
];

export const ACCOUNT_FEATURE_FLAGS_READ = "partition.feature_flags.read";
export const ACCOUNT_FEATURE_FLAGS_EDIT = "partition.feature_flags.edit";
export const PART_CALC_ENG_EDIT = "partition.calculation_engines.edit";
export const PART_DATA_MGMT_EDIT = "partition.data_management.edit";

export const MANAGEMENT_PERMISSIONS = [
    ...USERS_MANAGEMENT_PERMISSIONS,
    ...GROUPS_MANAGEMENT_PERMISSIONS,
    ...GLOBAL_ALERTS_PERMISSIONS,
    PERMISSION_POWER_USER_PERMISSION,
    VERSIONS_MANAGEMENT_PERMISSION,
    ...FEATURE_FLAGS_PERMISSIONS,
];

export const ASSET_ROLES_MANAGEMENT_PERMISSIONS = [
    "user_mgmt.asset_roles.read",
    "user_mgmt.asset_roles.edit",
];

export const BUSINESS_ROLES_MANAGEMENT_PERMISSIONS = [
    "user_mgmt.business_roles.read",
    "user_mgmt.business_roles.edit",
];

export const ATLASSIAN_GROUPS_MANAGEMENT_PERMISSIONS = [
    PERMISSION_POWER_USER_PERMISSION,
];

export const MARKETPLACE_GLOBAL_PERMISSIONS = [
    "marketplace.templates_management.deploy",
    "marketplace.templates_management.publish",
];

export const MARKETPLACE_MANAGEMENT_PERMISSIONS = [
    "marketplace.templates_management.deploy",
    "marketplace.templates_management.publish",
    "marketplace.templates_management.manage_non_approved",
];

export const MARKETPLACE_TEMPLATE_DEPLOY_PERMISSIONS = [
    "marketplace.templates_management.deploy",
    "marketplace.templates_management.deploy_non_approved",
    "marketplace.templates_management.upload_snapshot",
];

export const INTEGRATION_ADMIN_PERMISSIONS = ["integration.admin"];

export const ACCOUNT_PARTITION_EDIT_PERMISSIONS = ["partition.edit"];

export const ACCOUNT_PARTITION_PROVISIONING_PERMISSIONS = [
    "provisioning.partition",
];

export const ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS = [
    "provisioning.integration",
];

export const INTEGRATION_EDIT_ONLY_PERMISSIONS = ["integration.edit"];

export const INTEGRATION_EDIT_PERMISSIONS = [
    "integration.edit",
    "integration.admin",
];

export const INTEGRATION_GIT_MANAGER = ["integration.git.manager"];

export const INTEGRATION_READ_EDIT_PERMISSIONS = [
    "integration.read",
    "integration.edit",
    "integration.admin",
];

export const ACCOUNT_INTEGRATION_PERMISSIONS = [
    ...INTEGRATION_READ_EDIT_PERMISSIONS,
    "integration.dashboard_logs.read",
    ...ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
];

export const ACCOUNT_INTEGRATION_OVERVIEW_PERMISSIONS = [
    "integration.dashboard_logs.read",
    "integration.edit",
    "integration.admin",
];

export const ACCOUNT_PARTITION_PERMISSIONS = [
    "partition.activity_log.read",
    "partition.data_management.use",
    "partition.data_management.edit",
    "partition.edit",
    "partition.list.read",
];

export const ACCOUNT_DATA_LOAD_PERMISSIONS = [
    "partition.data_management.use",
    "partition.data_management.edit",
];

export const ACCOUNT_DATA_LOAD_EDIT_PERMISSIONS = [
    "partition.data_management.edit",
];

export const ACCOUNT_OVERVIEW_PERMISSIONS = ["partition.activity_log.read"];

export const ACCOUNT_EVENTS_JOBS_READ_PERMISSIONS = [
    "partition.events_jobs.read",
];

export const ACCOUNT_EVENTS_JOBS_EDIT_PERMISSIONS = [
    "partition.events_jobs.edit",
];

export const ACCOUNT_EVENTS_JOBS_PERMISSIONS = [
    ...ACCOUNT_EVENTS_JOBS_READ_PERMISSIONS,
    ...ACCOUNT_EVENTS_JOBS_EDIT_PERMISSIONS,
];

export const ACCOUNTS_ADMIN_EVENTS = ["partition.admin.events"];
export const ADMIN_ACTIVITY_LOG_READ = ["admin.activity_log.read"];

export const ACCOUNT_ALERT_PERMISSIONS = ["alerts.read", "alerts.edit"];

export const ACCOUNT_ACTIVITY_LOG_PERMISSIONS = ["account.activity_log.read"];

export const ACCOUNT_DEPLOYMENT_LOG_PERMISSIONS = ["account.deployment.read"];

export const LOGS_PERMISSIONS = [
    "account.activity_log.read",
    "account.deployment.read",
];

export const ACCOUNT_REPORTS_PERMISSIONS = ["reports.read", "reports.edit"];

export const ACCOUNT_REPORTS_EDIT_PERMISSIONS = ["reports.edit"];

export const ACCOUNT_SETTINGS_PERMISSIONS = ["account.edit"];

export const ACCOUNT_USER_MANAGEMENT_PERMISSIONS = ["account.user_mgmt"];

export const ADD_INTEGRATION_PERMISSIONS_EXTENDED = [
    ...ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
    "marketplace.templates.deploy",
];

export const CLUSTER_UPGRADE_PERMISSIONS = ["cluster.upgrade"];

export const ISV_CONNECTION_EDIT_PERMISSION = "isv_connections.edit";

export const EO_READ_PERMISSION = "event_orchestration.read";
export const EO_EDIT_PERMISSION = "event_orchestration.edit";

export const EO_PERMISSIONS = [EO_READ_PERMISSION, EO_EDIT_PERMISSION];

export const DEFAULT_WORKFLOW_EDIT_PERMISSION = "default.workflow.edit";
export const PROJECT_WORKFLOW_EDIT_PERMISSION = "account.workflow.edit";

export const ENABLE_DEEPLINKS_MANAGEMENT_PERMISSION = "enable.links.management";

export const MARKETPLACE_ACCELERATOR_PERMISSIONS = [
    "marketplace.templates.deploy",
];

export const INTEGRATION_OVERVIEW_PERMISSIONS = [
    "integration.dashboard_logs.read",
    ...INTEGRATION_EDIT_PERMISSIONS,
];

export const ACCOUNT_PRIVATE_MANAGE_PERMISSIONS = [
    "marketplace.account.accelerators-manage",
];

export const ACCOUNT_ALERT_EDIT_PERMISSIONS = ["alerts.edit"];

const createContainsInAny =
    (secContextKeys, permissions = []) =>
    secContext =>
        secContextKeys.some(
            key => !!intersection(permissions, secContext?.[key] ?? []).length,
        );

export const MARKETPLACE_ACCELERATOR_PERMISSION_FUNC = context =>
    hasAnyPermissionsFunc(context) ||
    includesPermission(context, MARKETPLACE_GLOBAL_PERMISSIONS);

export const TEMPLATE_MANAGEMENT_PERMISSION_FUNC = context => {
    const hasPermission = includesPermission(
        context,
        MARKETPLACE_MANAGEMENT_PERMISSIONS,
    );
    const hasProjectPermission = hasPermissionInSomeProject(
        ACCOUNT_PRIVATE_MANAGE_PERMISSIONS,
    )(context);

    return hasPermission || hasProjectPermission;
};

export const PARTITION_PERMISSION_FUNC = c =>
    includesPermissionInAssetTree(c, [
        ...ACCOUNT_PARTITION_PERMISSIONS,
        ...ACCOUNT_PARTITION_PROVISIONING_PERMISSIONS,
    ]);

export const INTEGRATION_PERMISSION_FUNC = c =>
    includesPermissionInAssetTree(c, ACCOUNT_INTEGRATION_PERMISSIONS);

export const ALERTS_PERMISSION_FUNC = c =>
    includesPermissionInAssetTree(c, ACCOUNT_ALERT_PERMISSIONS);

export const REPORTS_PERMISSION_FUNC = c =>
    includesPermissionInAssetTree(c, ACCOUNT_REPORTS_PERMISSIONS);

export const ACCOUNT_ACTIVITY_LOG_FUNC = createContainsInAny(
    ["projectSpecificPermissions"],
    ACCOUNT_ACTIVITY_LOG_PERMISSIONS,
);

export const ACCOUNT_DEPLOYMENT_LOG_FUNC = createContainsInAny(
    ["projectSpecificPermissions"],
    ACCOUNT_DEPLOYMENT_LOG_PERMISSIONS,
);

export const ACCOUNT_LOGS_FUNC = createContainsInAny(
    ["projectSpecificPermissions"],
    LOGS_PERMISSIONS,
);

export const ACCOUNT_SETTINGS_FUNC = createContainsInAny(
    ["projectSpecificPermissions"],
    ACCOUNT_SETTINGS_PERMISSIONS,
);

export const ACCOUNT_USER_MANAGEMENT_FUNC = createContainsInAny(
    ["projectSpecificPermissions"],
    ACCOUNT_USER_MANAGEMENT_PERMISSIONS,
);

export const ACCOUNT_INTEGRATION_ADMIN_FUNC = createContainsInAny(
    ["projectSpecificPermissions"],
    INTEGRATION_ADMIN_PERMISSIONS,
);

const hasPermissionInSomeProject =
    (permissions = []) =>
    context => {
        return Object.values(context.nonGlobalPermissions?.PROJECT || {}).some(
            projectPermission => containsAny(permissions, projectPermission),
        );
    };

export const WORKFLOW_PERMISSION_FUNC = context => {
    return (
        hasPermissionInSomeProject([PROJECT_WORKFLOW_EDIT_PERMISSION])(
            context,
        ) || includesPermission(context, [DEFAULT_WORKFLOW_EDIT_PERMISSION])
    );
};

export const includesPermission = (secContext, permissions) => {
    return (
        isEmpty(filter(permissions, p => !isEmpty(p))) ||
        (secContext.permissions &&
            intersection(secContext.permissions, permissions).length > 0)
    );
};

export const includesPermissionInAssetTree = (secContext, permissions) => {
    const accountId = secContext.currentAccountId;

    return includesPermissionInAccountAssetTree(
        secContext,
        accountId,
        permissions,
    );
};

export const includesPermissionInAccountAssetTree = (
    secContext,
    accountId,
    permissions,
) => {
    return (
        containsAny(
            secContext.getPermissions("PROJECT", accountId),
            permissions,
        ) ||
        containsInProjectSubAssets(
            secContext,
            accountId,
            "PARTITION",
            permissions,
        ) ||
        containsInProjectSubAssets(
            secContext,
            accountId,
            "INTEGRATION",
            permissions,
        )
    );
};

export const includesPermissionForPartition = (
    secContext,
    accountId,
    partitionId,
    permissions,
) => {
    return (
        containsAny(
            secContext.getPermissions("PROJECT", accountId),
            permissions,
        ) ||
        containsInProjectSubAsset(
            secContext,
            accountId,
            partitionId,
            "PARTITION",
            permissions,
        )
    );
};

export const includesPermissionForIntegration = (
    secContext,
    integrationId,
    accountId,
    permissions,
) => {
    accountId = accountId || secContext.currentAccountId;
    return (
        containsAny(
            secContext.getPermissions(EntityType.PROJECT, accountId),
            permissions,
        ) ||
        containsInProjectSubAsset(
            secContext,
            accountId,
            integrationId,
            EntityType.INTEGRATION,
            permissions,
        )
    );
};

export const hasAnyPermissionsFunc = context =>
    !isEmpty(context.nonGlobalPermissions);

export const hasOverviewPermission = permissions => {
    return hasPermission(permissions, OVERVIEW_PERMISSIONS);
};

export const hasManagementPermission = permissions => {
    return hasPermission(permissions, MANAGEMENT_PERMISSIONS);
};

export const hasMarketplaceGlobalPermission = permissions => {
    return hasPermission(permissions, MARKETPLACE_GLOBAL_PERMISSIONS);
};

export const hasPermission = (permissions, requiredPermissions) => {
    return (
        permissions &&
        permissions.some(permission => requiredPermissions.includes(permission))
    );
};

const containsAny = (arr1, arr2) => intersection(arr1, arr2).length > 0;

const containsInProjectSubAssets = (
    context,
    accountId,
    assetType,
    permissions,
) =>
    get(context.assetTree, ["PROJECT", accountId, assetType], []).filter(id =>
        containsAny(context.getPermissions(assetType, id), permissions),
    ).length > 0;

const containsInProjectSubAsset = (
    context,
    accountId,
    assetId,
    assetType,
    permissions,
) =>
    get(context.assetTree, ["PROJECT", accountId, assetType], [])
        .filter(id => id === assetId)
        .filter(id =>
            containsAny(context.getPermissions(assetType, id), permissions),
        ).length > 0;

export const hasPermissionForPackage = (
    permissions,
    requiredPermissions,
    packageName,
) => {
    const packagesPermissions =
        permissions?.permissions?.[EntityType.PACKAGE] || {};
    return packagesPermissions?.[packageName]?.includes(requiredPermissions);
};

export const hasPermissionInAccountsOrAssets = (
    nonGlobalPermissions,
    permissions,
) =>
    intersection(
        concat(
            ...values(nonGlobalPermissions.PROJECT),
            ...values(nonGlobalPermissions.PARTITION),
            ...values(nonGlobalPermissions.INTEGRATION),
        ),
        permissions,
    ).length > 0;
