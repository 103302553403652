import React from "react";
import PropTypes from "prop-types";
import { ButtonMenu as UCButtonMenu } from "@pricefx/unity-components";
import { useMemo } from "react";
import { useTrackButtonClick } from "../../../mixpanel/hooks/useTrackButtonClick.hook";
import "./ButtonMenu.style.less";
import { identity, map, pipe } from "lodash/fp";
import { filterVisibleButtons } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";

export const ButtonMenu = ({
    buttons,
    _trackingDisabled = false,
    align,
    ...props
}) => {
    const { wrapButtonDef: _wrap } = useTrackButtonClick();
    const addOnClickTracking = _trackingDisabled ? identity : _wrap;
    const buttonsTracked = useMemo(
        () => pipe(filterVisibleButtons, map(addOnClickTracking))(buttons),
        [buttons, addOnClickTracking],
    );
    const buttonMenu = <UCButtonMenu {...props} buttons={buttonsTracked} />;

    if (align === "left") {
        return <div className={"pmButtonMenu-alignLeft"}>{buttonMenu}</div>;
    }

    return buttonMenu;
};

ButtonMenu.propTypes = {
    buttons: PropTypes.array,
    align: PropTypes.oneOf(["left"]),
};

ButtonMenu.buttons = UCButtonMenu.buttons;
